const social = [
  {
    iconName: 'globe',
    link: 'http://brianlusina.surge.sh/',
    label: 'Website',
  },
  {
    iconName: 'twitter',
    link: 'https://twitter.com/BrianLusina',
    label: 'Twitter',
  },
  {
    iconName: 'linkedin',
    link: 'https://www.linkedin.com/in/brianlusina/',
    label: 'LinkedIn',
  },
  {
    iconName: 'github',
    link: 'https://github.com/BrianLusina',
    label: 'GitHub',
  },
  {
    iconName: 'medium',
    link: 'https://medium.com/@lusinabrian',
    label: 'Medium',
  },
];

export default social;
